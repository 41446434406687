
import { faSitemap, faUsers, faVideo, faPaperPlane, faHome, faListAlt, faTachometerAlt, faDatabase, faServer, faTags, faFileCode, faFileAlt, faUniversity, faFileVideo, faAlignCenter } from '@fortawesome/free-solid-svg-icons';

const menus = [
    { key: '/tenants', title: '企业管理', icon: faListAlt, component: 'Tenants' },
    { key: '/dashboard', title: '主页', icon: faHome, component: 'Dashboard' },
    { key: '/organize', title: '部门管理', icon: faSitemap, component: 'Organize' },
    { key: '/region', title: '区域管理', icon: faPaperPlane, component: 'Region' },
    { key: '/personal', title: '个人中心', icon: faTachometerAlt, component: 'Personal' },
    { key: '/template', title: '建会模板', icon: faAlignCenter, component: 'Template' },
    { key: '/paramtemp', title: '建会参数', icon: faFileVideo, component: 'Paramtemp' },
    { key: '/role', title: '角色权限', icon: faTags, component: 'Role' },
    { key: '/permission', title: '权限配置', icon: faServer, component: 'Permission' },
    { key: '/user', title: '用户管理', icon: faUsers, component: 'User' },
    { key: '/log', title: '日志管理', icon: faFileCode, component: 'Log' },
    { key: '/booking', title: '预约会议', icon: faFileCode, component: 'Booking' },
    { key: '/conflist', title: '会议列表', icon: faFileCode, component: 'Conflist' },
    { key: '/terminal', title: '终端配置', icon: faVideo, component: 'Terminal' },
    { key: '/room', title: '会议室管理', icon: faUniversity, component: 'Room' },
    { key: '/version', title: '版本管理', icon: faFileAlt, component: 'Version' },
    { key: '/menu', title: '菜单管理', icon: faFileAlt, component: 'Menu' },
    { key: '/ldap', title: 'LDAP', icon: faFileAlt, component: 'Ldap' },
    { key: '/dicts', title: '字典管理', icon: faFileAlt, component: 'Dicts' },
    { key: '/facility', title: '设备管理', icon: faFileAlt, component: 'Facility' },
]

export const getMenus = () => {
    return menus
}

