/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';


class Framework extends React.Component {
    render () {
        return <div style={{display : 'flex', flexDirection : 'column',  width : '100%' }}>
            <div style={{height : 36, display: 'flex', alignItems : 'center', fontSize : 18, fontWeight : 500, paddingLeft : 12, borderLeft : '4px solid #55a5ed'}}>{this.props.title}</div>
            <div style={{flex : 1, overflowY : 'scroll'}}>
                {this.props.content}
            </div>
        </div>
    }
}

export default Framework;