import axios from "axios";
import {Logger} from "./ConsoleUtils";

let protocol = window.location.protocol
let host = window.location.host
let windowUrl = host.indexOf('localhost') != -1 ?(window.API_U +window.API_P):(protocol+ '//'+ host)
let path = window.API_PATH
console.log(windowUrl + '------')

let axiosInstance = axios.create({
    baseURL: windowUrl + path,
    timeout: 300000,
    withCredentials: true,
    headers : {
        // 'Authorization' : auth,
        'Content-Type': 'application/json;charset=UTF-8',
    }
});


const fetchData = async (type, url, data = undefined) => {
    try{
        return await axiosInstance({
            method: type,
            url: url,
            data : data
        });
    }catch(e){
        console.log('err', e);
        if(!!e.response && e.response.status > 400){
            switch(e.response.status){
                case 401 : {
                    Logger.trace('token已失效', e.response);
                    window.localStorage.removeItem('zhToken');
                    // alert('token已失效');
                    if(window.location.href.indexOf("/p/") > 0){
                        window.location.href = window.location.origin;
                    }
                    
                    break;
                }
                case 9000 : {
                    Logger.trace('接口调用失败', e.response);
                    break;
                }
                default : {
                    Logger.trace('接口调用失败', e.response);
                }
            }
        }else{
            Logger.error("网络错误", e);
        }
        return e.response;
    }
};

export const SetToken = (token) => {
    axiosInstance.defaults.headers.common['vmstoken'] = token;
}

export const ApiUtil = {
    get : async (url) => {
        return await fetchData('get', url);
    },
    post : async (url, data) => {
        return await fetchData('post', url, data);
    },
    put : async (url, data) => {
        return await fetchData('put', url, data);
    },
    patch : async (url, data) => {
        return await fetchData('patch', url, data);
    },
    delete : async (url, data) => {
        return await fetchData('delete', url, data);
    }
}






