
let defaultState = {
    
    data : [],
    pagination: {
        showQuickJumper : true,
        total : 0,
        defaultCurrent : 1,
        current : 1,
        size : 'default',
        pageSizeOptions : ['10','20', '50', '100'],
        pageSize : 20
    },
    loading: false,
    pageSize : 20,
    size : 'default',
    filters : {
        startTime : null,
        endTime : null,
        statusList : [0, 1],
        historyList : ['2', '10', '-1'],
        typeList : null,
        desc : null,
        myBooking : false,
        myControl : true,
        myOrg : false,
        allConferenceType: ['6', '8'],
    },

    drawerVisible : false,

    // selectedKeys : [],

    // orgMap : null,

    
    // confNumeric : [],//号码段
    // confParamAll : [],
    // defaultCallParamList : [],
    // orgList : [],

    // submitBase : 0,
    // submitParticipants : 0,
    // recordPath:null,

    // //dict
    confStatus : new Map(),
    confType : new Map(),
    // cloudType : new Map(),
    // cloudLicense : new Map(),
    // participantType : new Map(),


    // tenant : new Map(),

    // approvalAuth : false,
    // recordShowStatus : false,

    // myMeeting : true,
    // recordMeeting: false,
    // bookingModalVisible : false,
    // archiveModalVisible : false,
    // confArchive : null,
    // recordModalVisible : false,
    // confRecord : null,
    // me : {},

    // basicDetail: null,
    // peopleDetail: null,
    // roomDetail: null,

    // live: '',
    // record: '',
    // liveURL: '',

    // liveInfo: [],

    // living: '',
};
export const conferencesReducer = (state = defaultState, action) => {
    switch (action.type) {

        case 'setConfReducer' : {
            state = {
                ...state,
                ...action.payload
            };
            break;
        }

        default : {

        }

    }
    return state;
};
