import React from 'react';
import { connect } from "react-redux";
import { Layout, Dropdown, Menu,  message, Avatar } from "antd";
import {LogoutOutlined, CaretDownOutlined, LoadingOutlined, ClusterOutlined, UserOutlined} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { logout } from '../common/commonActions';
import {menuEmitter} from './Menu';

const { Header } = Layout;
class PortalHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exiting : false,
        };
    }
    render() {
        return (

            <Header
                style={{ display: 'flex', background: '#fff', padding: "0px", zIndex: 1, boxShadow: 'rgb(0 0 0 / 0.12) 0px 1px 4px 1px' }}
                id={'portal-header'}
            >
                <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center', flex: 1, width : 50, fontWeight : 600, fontSize : 16 }}>
                    {window.company}会议管理平台
                </div>
                <div>
                <Dropdown
                    placement="bottomRight"
                    overlay={
                        <Menu>
                            <Menu.Item
                                key='1'
                                icon={<ClusterOutlined />}
                                onClick={() => {
                                    message.info('敬请期待...')
                                    // window.open(window.origin + '/admin');
                                }}
                            >
                                门户平台
                            </Menu.Item>
                            <Menu.Item
                                key='2'
                                icon={<UserOutlined />}
                                onClick={() => {
                                    menuEmitter.emit('setSeleted', ['2'])
                                    window.location.href = window.origin + '/#/personal';
                                }}
                            >
                                个人中心
                            </Menu.Item>
                            <Menu.Item
                                key='3'
                                icon={!this.state.exiting ? <LogoutOutlined /> : <LoadingOutlined  spin />}
                                onClick={async () => {
                                    
                                    try{
                                        this.setState({
                                            ...this.state,
                                            exiting : true
                                        })
                                        await logout();
                                        window.localStorage.removeItem('zhToken');
                                        window.location.reload();
                                        
                                    }catch(e) {
                                        console.log(e);
                                    }
                                }}
                            >
                                退出登录
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <div style={{float : 'right', cursor : 'pointer', height : '100%', display : 'flex', alignItems :'center', paddingRight : 10, color :'#4a4a4a'}}>
                        <Avatar size={24} icon={<UserOutlined />} />
                        <span style={{paddingLeft : 10,}}>
                            {this.props.portal.name}
                        </span>
                        <span style={{paddingLeft : 5, }}>
                            <CaretDownOutlined style={{fontSize : 12}}/>
                        </span>
                    </div>
                </Dropdown>
                </div>
            </Header>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        portal : state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer : (target) => {
            dispatch ({
                type : "setPortalReducer",
                payload : target
            });
        },

   
    }
};

export default connect(mapStateToProps, mapDispachToProps)(withRouter(PortalHeader));

