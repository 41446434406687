import React from 'react'

const Personal = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/personal/personal'))
const Dashboard = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/dashboard/dashboard'))
const Tenants = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/tenants/tenants'))
const Organize = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/organize/organize'))
const Region = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/region/region'))
const Template = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/template/template'))
const Paramtemp = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/paramtemp/paramtemp'))
const Role = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/role/role'))
const Permission = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/permission/permission'))
const User = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/user/user'))
const Log = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/log/log'))
const Booking = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/booking/booking'))
const Conflist = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/booking/conflist'))
const Terminal = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/terminal/terminal'))
const Room = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/room/room'))
const Version = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/version/version'))
const Menu = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/menu/menu'))
const Ldap = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/ldap/ldap'))
const Dicts = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/dicts/index'))
const Facility = React.lazy(() => import(/* webpackChunkName: "personal" */ '../components/facility/index'))
export const getComponents = () => {
  return {
    Personal,
    Dashboard,
    Tenants,
    Organize,
    Region,
    Template,
    Paramtemp,
    Role,
    Permission,
    User,
    Log,
    Booking,
    Conflist,
    Terminal,
    Room,
    Version,
    Menu,
    Ldap,
    Dicts,
    Facility,
  }
}
