const connKey = 'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDBm8qfgWujNWehEHoUgzpjqXm2suQN1fx6O41TKCaJO46xxIzABrMT0XlatkEFuv2JM3GBe0FNxuJQrR4RxnZS6nmqFLfckxSVVa7mOqIE0yRHGzs2+Lg6Bwz6CFgnpXf3p6dSzHUeXElxLkjlox76KDKamRAP4X+qA1D3O8OvrVmFm+l5NUns10VWfJVldAUtgE/qZuci2r2O9zyXTm5qHGhpLGFIleZo4t0qk4plmhzBzZWrImRzfLYuNFH+JasD7c/prcutzDw+KEh85o+EmroMiiUqREiapkjgNMOTMLB7AtJMPohNfCYwcKYnD/QkORjbE46cwXsS3XEFkYWpAgMBAAECggEALm2RtZZvnX/dDf7Jh8nAYs3tZiaYEclS9mz5gwuJ0jb6DR85MYNpxkLheyNhjGI/EnvHqGlKS8B53SbmoxSYo2GfM24seTS8JuOZQZSl7cpEVNju5kvWwfOW5Q7DNR61GiqWMYDeRR2UbxPOX2MytoKVQAWcmHJXDwokjGVElAfuktWy49wKQskyy1zdDTkcCHpCXrfhM5MEFrq2+WwNWUkLou0eNIYCOxf2/ob3mywBKJ7gtko85I73lI8kEgIA/7F8Njxtt/l9WSuWFAc/1O//XnijMtGb8jOx4xX1iVFH3dyYniWMTYFIT5b5dpQQWrENZjb7f5XNUhL7Y0lyAQKBgQD46MzgNynPiIzQ/hYOPq42zUwhiV73FWdJvXsYMczovgsqpfKMegbY2L9SRjtfKv1cGScAjZOM52IfxMfzmV/QfuoMGQAln5pC0bbCFD+GUcwQsd9vsliEInwgtPpqwDsFKRtux+jdckA6njdVKBOBrZyzXEWNEaPJdz1eiZsMcQKBgQDHH7Qp4EMnArH2Y3uRFh9gPEPnFPTpznDqs1ZCK7pb0eJg8pUY3mNrHB/cGgBeRt46wH9WJ71P2IjlMZrhVu0HYoGmG7pric9e9ZUtiqR/QlE68FTOK+9vtC3/7AdRu9ktMNyppVVumh3rKoGfe/8izBLIzkoZ72+UrjChd0IIuQKBgHtPQWf2jBUPLcu/hIesD7yAei3HEy/O/6ttXlT/HudSds+7BG0Jauj0f9uvhWxBLOD6aPMCME3GtzrLzs7kda4hoas6m3MIHXDq2eJOC6ZsUW8X33xDW0yilOHTJpfKAbMtyTRZfwhtuFoR1ZGvV/KPYqkMljwrt/tvwgxamWBRAoGAKB1/+0u925cUaTHji/gr/ARIHb7AFHZNk0rr3srWcXcYJzXXybnLx6FT3dCzMEsQrD9pp967qeFSuvHCZRFREULMIb3GUbHiwBuzoQgkWarvmHMgcqSxSQzAk6VmWBYDC7KjUd4IFw8fZegbUxj07nq0chX1IcJ19Tseevj+qakCgYEAq1s+6arH5jI0eBjIb9AnhZTW5oBFhkxOiAs7QiggE/cFuVT+9/JmfDhjG/UwVWEHY4tgML8vO/TmS7gT2GFh5vASHFatRgbjLx9HI0+KGQjjwzdCAyQMMVwbwvH36dCOJzJLb20G94AAj99gkTuCPRWrR7JvBmJjL3qKLflBKPo=';
const pubKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyIZzn4Plfih7oisz1ouUMa1rUAPwtA6KxhSYaILv4euZb02SJDQgpnNvXJ3UjT2DAbzOG/9LnJivZo6TpyOSE+zvRS1nYvbtIGgMckW4QPRQt0B/G9NKshUXGrkWNEt/kwHlNL5KcBKzBvWdYyXvGQz422vId7jIZfnzIicXtsVHxTKe1Zi+i92/AiD5umkEqGl0TCrmAsROgae2EMjR/VGoNO8o3Rvmp8hBAYCavTML644FPGEoCGEB4Bq2L8P0UJT+qEZnkeA6V45yfQ33InWLPjxg/6WUx59FxQti7vS5ZGPgR90LDUu8KUaSpsc4a6XuC9vUukWTtZxEt2Z0lQIDAQAB"


const decrypter = new window.JSEncrypt();
const encrypter = new window.JSEncrypt();

export const decrypt = (str) => {
    decrypter.setPrivateKey(connKey);
    return decrypter.decrypt(str);
}


export const encrypt = (str) => {
    encrypter.setPublicKey(pubKey);
    return encrypter.encrypt(str);
}