import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { Layout } from "antd";
import { Route, Redirect, Switch, } from "react-router-dom";
import Header from './Header';
import Menu from "./Menu";
import Loading from '../common/extendedAntdWidget/Loading';
import Framework from "./Framework";
import { getMenus } from "./config";
import { getComponents } from './pages';
const { Content, Sider } = Layout;
class PortalContent extends React.Component {

    state = {
        collapsed: false,
    }
    onCollapse = (collapsed) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    render() {
        const routes = getMenus()




        // const getMenuName = (item) => {
        //     if(item.childList && item.childList.length > 0){
        //         return '11'
        //     }else{
        //         return 'ss'
        //     }
        // }


        const createMenu = (item) => {

            const me = this.props.portal.myData;
            let menus = me.menus


            for (let i = 0; i < menus.length; i++) {
                let menu = menus[i]
                if (menu.dataUrl === item.key) {
                    item.title = menu.name
                    break
                }
            }

            const route = (r) => {
                const AllComponents = getComponents()
                const Component = r.component && AllComponents[r.component];
                return (
                    <Route
                        key={r.uuid}
                        path={r.key}
                        component={() => {
                            return <Suspense fallback={<Loading />}><Framework title={item.title} content={<Component />} /></Suspense>
                        }}
                    />
                );
            };

            return route(item)

        }
        const createRoute = (key) => {
            return createMenu(routes[key])
        }

        return (
            <Layout style={{ minHeight: '100vh', overflow: 'hidden', display: 'flex' }} id={'my-root'}>
                <Header />
                <Layout style={{ background: '#021629' }}>
                    <Sider style={{
                        overflow: 'auto',
                    }} collapsible onCollapse={this.onCollapse} collapsed={this.state.collapsed}>
                        <Menu toggle={() => {
                            let open = this.state.collapsed
                            this.setState({
                                collapsed: !open
                            })
                        }} />
                    </Sider>

                    <Layout className="site-layout" style={{ padding: '24px' }}>
                        <Content style={{ backgroundColor: '#e3e6ed', flex: 1, overflowY: 'hidden', overflowX: 'hidden' }}>
                            <div
                                id={"portal-content"}
                                style={{ height: '100%', flex: 1, boxShadow: '0 0 6px 0 rgb(0 0 0 / 14%)', background: 'white', display: 'flex' }}
                            >
                                <Switch>
                                    <Redirect
                                        from={"/"}
                                        to={"/dashboard"}
                                        exact
                                    />
                                    {Object.keys(routes).map((key) => createRoute(key))}
                                </Switch>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        portal: state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer: (target) => {
            dispatch({
                type: "setPortalReducer",
                payload: target
            });
        },


    }
};

export default connect(mapStateToProps, mapDispachToProps)(PortalContent);