import {createStore, combineReducers, applyMiddleware} from "redux";
import {createLogger} from 'redux-logger';
import {portalReducer} from '../reducer/portalReducer';
import {bookingReducer} from '../reducer/bookingReducer';
import { conferencesReducer} from '../reducer/conferencesReducer';

let watcher = window.localStorage.getItem('reduxWatcher');
let loggerMode = window.localStorage.getItem('loggerMode') === 'true' ? true : false;
const rdLogger = createLogger({
    predicate: (getState, action) => action.type === watcher || loggerMode
});


window.setReduxWatcher = (reducer) => {
    watcher = reducer;
}

export const portalStore = createStore(combineReducers(
    {
        portal : portalReducer,
        booking : bookingReducer,
        conference : conferencesReducer,
    }),
    {},
    applyMiddleware(rdLogger)
);

// export const bookingStore = createStore(combineReducers(
//     {
//         booking : bookingReducer,
//     }),
//     {},
//     applyMiddleware(rdLogger)
// );